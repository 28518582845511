import { SearchFilterContentTypes } from '../types/shared';

const getTrendingTagLinks = (keyword: string, contentType: SearchFilterContentTypes): string => {
    const trendingTagLinksMap = {
        [SearchFilterContentTypes.All_videos_content_type]: `/video/search/${keyword}`,
        [SearchFilterContentTypes.Templates]: `/video/search/${keyword}?media-type=templates`,
        [SearchFilterContentTypes.Premiere_pro_templates]: `/templates/premiere-pro-templates/search/${keyword}`,
        [SearchFilterContentTypes.After_effects]: `/templates/after-effects-templates/search/${keyword}`,
        [SearchFilterContentTypes.Davinci_resolve]: `/templates/davinci-resolve-templates/search/${keyword}`,
        [SearchFilterContentTypes.Apple_motion]: `/templates/apple-motion-templates/search/${keyword}`,
        [SearchFilterContentTypes.All_audio_content_type]: `/audio/search/${keyword}`,
        [SearchFilterContentTypes.All_images_content_type]: `/images/search/${keyword}`,
        [SearchFilterContentTypes.Footage]: `/video/search/${keyword}`,
        [SearchFilterContentTypes.Motion_bgs]: `/motion-graphics/search/${keyword}`,
        [SearchFilterContentTypes.Music]: `/audio/search/${keyword}?media-type=music`,
        [SearchFilterContentTypes.Sound_effects]: `/audio/search/${keyword}?media-type=sound-effects`,
        [SearchFilterContentTypes.Photos]: `/images/search/${keyword}?media-type=photos`,
        [SearchFilterContentTypes.Vectors]: `/images/search/${keyword}?media-type=vectors`,
        [SearchFilterContentTypes.Illustrations]: `/images/search/${keyword}?media-type=illustrations`,
    };
    return trendingTagLinksMap[contentType];
};

export default getTrendingTagLinks;
