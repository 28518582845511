import { SearchFilterContentTypes } from '../types/shared';
import { SearchOrigins } from './constants';

export const AUDIO_SEARCH_ROUTE = '/audio/search';
export const IMAGE_SEARCH_ROUTE = '/images/search';
export const VIDEO_SEARCH_ROUTE = '/:path(all-video/.*|motion-graphics/.*|video/.*|templates/.*)';

export interface SearchUrlProps {
    searchRoute?: string;
    contentType: SearchFilterContentTypes;
    searchTerm: string;
    mediaType?: string;
    categoryUrlId?: string;
    contributorPortalId?: string;
    searchOrigin: SearchOrigins;
    similarTo?: number;
    usageRights?: string;
}

export function getSearchUrl(props: SearchUrlProps): string {
    props.searchRoute = getSearchRoute(props);
    const searchPath = getSearchPath(props);
    const searchParams = getSearchParams(props);

    return Array.from(searchParams).length > 0 ? `${searchPath}?${searchParams.toString()}` : searchPath;
}

// searchRoute is one of the *_SEARCH_ROUTE constant values above
export function getSearchRoute(props: SearchUrlProps): string {
    if (props.searchRoute) {
        return props.searchRoute;
    } else if (isAssetId(props.searchTerm)) {
        return getSearchRouteByAssetId(props.searchTerm);
    } else {
        return getSearchRouteByContentType(props.contentType);
    }
}

// searchPath is the actual url path we generate
function getSearchPath(props: SearchUrlProps): string {
    let searchPath = isFlatUrl(props.searchRoute)
        ? `/${getMediaTypePath(props.contentType)}/search`
        : props.searchRoute;

    if (props.searchTerm) {
        const cleanedTerm = encodeURIComponent(props.searchTerm).replace(/%20|%2520|%2b| /g, '-');
        searchPath += `/${cleanedTerm}`;
    }

    return searchPath as string;
}

export function getSearchParams(props: SearchUrlProps): URLSearchParams {
    const searchParams = new URLSearchParams();
    if (props.searchOrigin) {
        searchParams.append('search-origin', props.searchOrigin);
    }
    if (props.mediaType) {
        searchParams.append('media-type', encodeURIComponent(props.mediaType));
    } else if (!isFlatUrl(props.searchRoute) && props.contentType) {
        const mediaType = getMediaTypeParam(props.contentType);
        if (mediaType) {
            searchParams.append('media-type', encodeURIComponent(mediaType));
        }
    }
    if (props.similarTo) {
        searchParams.append('search_similar_id', props.similarTo.toString());
    }
    if (props.usageRights && props.usageRights !== 'all') {
        searchParams.append('usageRights', props.usageRights);
    }
    if (props.categoryUrlId) {
        searchParams.append('categories', encodeURIComponent(props.categoryUrlId));
    }
    if (props.contributorPortalId) {
        searchParams.append('portal_artist_ids', encodeURIComponent(props.contributorPortalId));
    }

    return searchParams;
}

const isFlatUrl = (searchRoute?: string) => searchRoute === VIDEO_SEARCH_ROUTE;

export function getMediaTypeParam(contentType: SearchFilterContentTypes | string): string {
    switch (contentType) {
        case SearchFilterContentTypes.All_audio_content_type:
        case SearchFilterContentTypes.All_videos_content_type:
        case SearchFilterContentTypes.All_images_content_type:
            return '';
        case SearchFilterContentTypes.Sound_effects:
            return 'sound-effects';
        case 'motionbackgrounds':
            return SearchFilterContentTypes.Motion_bgs.toString();
        default:
            return contentType.toString();
    }
}

/**
 * Get media type for flat search urls. Currently only used for video searches
 * e.g. /{mediaType}/search/{keyword}
 */
function getMediaTypePath(contentType: SearchFilterContentTypes): string {
    switch (contentType) {
        case SearchFilterContentTypes.Footage:
            return 'video';
        case SearchFilterContentTypes.Motion_bgs:
            return 'motion-graphics';
        case SearchFilterContentTypes.Templates:
            return 'templates';
        case SearchFilterContentTypes.Premiere_pro_templates:
            return 'templates/premiere-pro-templates';
        case SearchFilterContentTypes.After_effects:
            return 'templates/after-effects-templates';
        case SearchFilterContentTypes.Davinci_resolve:
            return 'templates/davinci-resolve-templates';
        case SearchFilterContentTypes.Apple_motion:
            return 'templates/apple-motion-templates';
        default:
            return 'all-video';
    }
}

function getSearchRouteByContentType(contentType: SearchFilterContentTypes): string {
    switch (contentType) {
        case SearchFilterContentTypes.All_videos_content_type:
        case SearchFilterContentTypes.Footage:
        case SearchFilterContentTypes.Motion_bgs:
        case SearchFilterContentTypes.Templates:
        case SearchFilterContentTypes.Premiere_pro_templates:
        case SearchFilterContentTypes.After_effects:
        case SearchFilterContentTypes.Davinci_resolve:
        case SearchFilterContentTypes.Apple_motion:
            return VIDEO_SEARCH_ROUTE;
        case SearchFilterContentTypes.All_audio_content_type:
        case SearchFilterContentTypes.Music:
        case SearchFilterContentTypes.Sound_effects:
            return AUDIO_SEARCH_ROUTE;
        case SearchFilterContentTypes.All_images_content_type:
        case SearchFilterContentTypes.Illustrations:
        case SearchFilterContentTypes.Photos:
        case SearchFilterContentTypes.Vectors:
        case SearchFilterContentTypes.Snapshots:
            return IMAGE_SEARCH_ROUTE;
        default:
            return '';
    }
}

export const isAssetId = (searchTerm = '') => /^SB[VIA]-\d{9,}$/i.test(searchTerm);

export function getSearchRouteByAssetId(assetId = ''): string {
    const contentInitial = assetId.substring(2, 3);
    switch (contentInitial) {
        case 'V':
            return VIDEO_SEARCH_ROUTE;
        case 'I':
            return IMAGE_SEARCH_ROUTE;
        case 'A':
            return AUDIO_SEARCH_ROUTE;
        default:
            return '';
    }
}
