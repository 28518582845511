import { SearchFilterContentTypes } from '../types/shared';

function getTypeaheadPath(contentType: SearchFilterContentTypes): string {
    switch (contentType) {
        case SearchFilterContentTypes.All_audio_content_type:
        case SearchFilterContentTypes.Music:
        case SearchFilterContentTypes.Sound_effects:
            return '/api/audio/typeahead';
        case SearchFilterContentTypes.All_videos_content_type:
        case SearchFilterContentTypes.Footage:
        case SearchFilterContentTypes.Motion_bgs:
        case SearchFilterContentTypes.Templates:
        case SearchFilterContentTypes.Premiere_pro_templates:
        case SearchFilterContentTypes.After_effects:
        case SearchFilterContentTypes.Davinci_resolve:
        case SearchFilterContentTypes.Apple_motion:
            return '/api/video/typeahead';
        case SearchFilterContentTypes.All_images_content_type:
        case SearchFilterContentTypes.Illustrations:
        case SearchFilterContentTypes.Photos:
        case SearchFilterContentTypes.Vectors:
        default:
            return '/api/images/typeahead';
    }
}

function fetchTypeahead(
    contentType: SearchFilterContentTypes,
    query = '',
    typeaheadEnhancementExperiment = false,
    timerTelemetry: () => Promise<void>,
) {
    const typeaheadTimer = timerTelemetry;

    return new Promise((resolve, reject) => {
        fetch(`${getTypeaheadPath(contentType)}?keywords=${encodeURIComponent(query)}`)
            .then((response) => response.json())
            .then((data) => {
                if (typeaheadEnhancementExperiment || data?.data?.keywords?.length) {
                    resolve(data.data);
                }
            })
            .catch(reject)
            .finally(typeaheadTimer);
    });
}

export default fetchTypeahead;
